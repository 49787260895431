.landing-main {
    display: flex;
    flex-direction: column;
}

.landing-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1152px;
    width: 100%;
}

.landing-section-one {
    background-color: #C0F4CC;
}

.navbar-option-text {
    color: #9399A5;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.navbar-login-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #2E3443;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.navbar-sign-up-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #6BD47B;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.section-one-text-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 80px;
}

.section-one-title {
    color: #2E3443;
    text-align: center;
    font-family: "Inter";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px;
}

.section-one-description {
    max-width: 760px;
    color: #2E3443;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-top: 24px;
}

.get-started-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #6BD47B;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;
}

.section-one-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.section-one-image img {
    max-width: 1152px;
}

.landing-section-two {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.section-two-formula-generator-block {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 120px;
    margin-bottom: 80px;
}

.section-two-formula-block {
    display: flex;
    flex-direction: column;
}

.section-two-formula-title {
    color: #2E3443;
    font-family: "Inter";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    max-width: 564px;
}

.section-two-formula-description {
    color: #2E3443;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    max-width: 564px;
    margin-top: 24px;
}

.section-two-formula-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #6BD47B;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 230px;
    margin-top: 40px;
}

.section-two-live-generation {
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 14px;
    border: 1px solid #F7F6F7;
    background: #F7F6F7;
    margin-bottom: 120px;
}

.live-generation-title {
    color: #2E3443;
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.live-generation-form-block {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.generation-block-step {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 524px;
    width: 100%;
}

.generation-step-block-title {
    color: #2E3443;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 8px;
}

.generation-step-block-input {
    resize: none;
    width: 100%;
    height: 100%;
    min-height: 75px;
}

.generation-step-block-input:focus {
    outline: none;
}

.generation-step-block-input-wrapper {
    display: flex;
    padding: 16px 24px;
    width: 100%;
    background-color: white;
}

.generation-step-block-content-wrapper {
    border-radius: 8px;
    border: 1px solid #D6D7DA;
    background: #FFF;
    overflow: hidden;
}

.generation-step-block-actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    gap: 16px;
    padding: 8px;
}

.generation-step-block-count {
    color: #9399A5;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.generation-step-block-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #6BD47B;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.generation-step-block-button-copy {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #2E3443;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.live-generation-third-step {
    display: flex;
    flex-direction: column;
}

.generation-third-step-img-wrapper {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
}

.landing-section-third {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #C0F4CC 0%, rgba(255, 255, 255, 0.00) 100%);
    padding: 120px 0;
}

.section-third-exmples-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.exmples-information-title {
    color: #2E3443;
    text-align: center;
    font-family: "Inter";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}

.exmples-information-description {
    color: #2E3443;
    text-align: center;
    max-width: 760px;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-top: 24px;
}

.examples-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.example-block {
    display: flex;
    align-items: center;
    gap: 24px;
}

.example-block-main-info {
    display: flex;
    flex-direction: column;
    max-width: 368px;
    width: 100%;
}

.example-main-info-title {
    color: #2E3443;
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.example-block-results {
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;
}

.example-block-description-text {
    padding: 24px;
    border-radius: 8px 8px 0px 0px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.example-block-description-title {
    color: #9399A5;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.example-block-description-content {
    color: #9399A5;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.example-block-result-text {
    padding: 24px;
    border-radius: 0px 0px 8px 8px;
    background: #6BD47B;
    display: flex;
    flex-direction: column;
    gap: 16px;
    word-wrap: break-word;
}

.section-third-learn-more {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #6BD47B;
    color: #2E3443;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
    max-width: 130px;
}

.border-none {
    border: none !important;
}

.example-block-result-title {
    color: #FFF;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.example-block-result-content {
    color: #FFF;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.example-block-copy-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
}

.example-block-copy-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #FFF;
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.landing-section-fourth {
    padding: 120px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-fourth-tips-information {
    display: flex;
    flex-direction: column;
}

.tips-information-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tips-information-title {
    color: #2E3443;
    font-family: "Inter";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}

.tips-information-description {
    max-width: 760px;
    margin-top: 24px;
    color: #2E3443;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 80px;
}

.tips-list-block {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.tip-item-block {
    border-radius: 8px;
    border: 1px solid #C0F4CC;
    background: #F0FCF2;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: calc(50% - 12px);
}

.tip-item-title {
    color: #2E3443;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.tip-item-description {
    color: #2E3443;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.landing-section-five {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-faq-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    background-color: #6BD47B;
    border-radius: 24px;
    padding: 120px 96px;
}

.section-five-faq-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-header-title {
    color: #FFF;
    font-family: "Inter";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
}

.faq-header-button {
    display: flex;
    height: 44px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #FFF;
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.faq-questions-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 80px;
}

.faq-question-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #FFF;
    cursor: pointer;
}

.faq-question-main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.faq-question-title {
    color: #FFF;
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
}

.landing-section-six {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
}

.faq-question-results {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.faq-line-block {
    margin-top: 24px;
    border-top: 1px solid white;
    width: 100%;
    height: 1px;
    margin-bottom: 24px;
}

.faq-question-answers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.faq-questions-answer-block-item {
    display: flex;
    gap: 8px;
    width: calc(50%- 12px);
}

.faq-question-letter {
    color: #FFF;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.faq-question-answer-text {
    color: #FFF;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.faq-revert-icon {
    transform: rotate(180deg);
}

.checkout-header-wrapper {
    background: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    padding: 0 24px;
}

.checkout-card-img {
    width: 32px;
    height: 32px;
}
.checkout-stripe-img {
    height: auto;
    width: 144px;
}

.checkout-page {
    background: #f3f4f6;
    width: 100%;
    min-height: calc(100vh - 76px);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    overflow: auto;
}

.checkout-header {
    width: 100%;
    border-radius: 14px;
    background: #FFF;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-header span {
    color: #2E3443;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.checkout-header svg {
    font-size: 27px;
}

.checkout-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    gap: 24px;
    max-width: 1440px;
}

.checkout-field-block {
    width: calc(100% / 3 * 2);
    background: #ffffff;
    border-radius: 16px;
    height: 100%;
    padding: 40px;
}

.loading-block {
    height: calc(100vh - 76px);
    width: 100%;
    background: #f3f4f6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-content {
    padding-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes ldio-japzwhp0h9j {
    0% {
      top: 96px;
      left: 96px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 18px;
      left: 18px;
      width: 156px;
      height: 156px;
      opacity: 0;
    }
  }.ldio-japzwhp0h9j div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-japzwhp0h9j 1s cubic-bezier(0,0.2,0.8,1) infinite;
  }.ldio-japzwhp0h9j div:nth-child(1) {
    border-color: #6bd47b;
    animation-delay: 0s;
  }
  .ldio-japzwhp0h9j div:nth-child(2) {
    border-color: #6bd47b;
    animation-delay: -0.5s;
  }
  .loadingio-spinner-ripple-wsf5cxo48ch {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-japzwhp0h9j {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-japzwhp0h9j div { box-sizing: content-box; }

.under-text {
    color: var(--Grey-1, #7D8898);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-top: 24px;
}

.checkout-body-header {
    width: 100%;
    color: #2E3443;
    font-family: "Inter";
    font-size: 33px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}

.checkout-card-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid #F2F2F2;
}

.checkout-card-field span {
    color: #2E3443;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.checkout-new-payment-method-title {
    color: #334155;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.56px;
    line-height: 34px;
}

.select-payments-block-wrapper {
    align-items: center;
    background: #edf3f9;
    border-radius: 100px;
    display: flex;
    padding: 4px;
}

.checkout-new-selected-plan-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    width: 100%;
}

.change-selected-plan-block {
    align-items: center;
    border: 1px solid #BFC2CA;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 24px;
}

.checkout-new-due-total-block {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.change-selected-plan-block-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.change-selected-plan-block-change-btn {
    color: #419547;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
}

.change-selected-plan-block-title {
    color: #334155;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.change-selected-plan-block-name {
    color: #334155;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.checkout-new-due-total-price, .checkout-new-due-total-title {
    color: #334155;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.46px;
    line-height: 28px;
    text-align: center;
}

.selecet-payment-method-element {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 36px;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 100px;
}

.selecet-payment-method-element.active {
    align-items: center;
    background: #fff;
    border-radius: 100px;
    display: flex;
    gap: 8px;
    height: 36px;
    justify-content: center;
    padding: 8px 12px;
}

.selecet-payment-method-element.active .select-payment-method-name {
    color: #334155;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.checkout-address-block {
    padding: 24px;
    padding-bottom: 0;
}
.checkout-address-block-padding {
    padding: 24px 0;
}

.checkout-address-block h2 {
    color: var(--black, #2E3443);
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
}

.checkout-address-block p {
    color: var(--black, #2E3443);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 16px;
}

.checkout-input-block input {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: var(--grey-1, #F1F5F9);
    border: none;
}

.checkout-input-block span {
    color: var(--black, #2E3443);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    display: block;
    margin-bottom: 4px;
}

.checkout-address-fields {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
}

.checkout-input-block-width {
    flex: 0 0 calc(50% - 8px);
}

.payment-element-wrapper {
    padding: 0px 0px 24px;
}

.checkout-body-right {
    width: calc(100% / 3);
    background: #ffffff;
    border-radius: 16px;
    overflow: hidden;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.checkout-guarantee-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.checkout-guarantee-icon-wrapper {
    align-items: center;
    background: rgba(107, 212, 123, 0.1);
    border-radius: 8px;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px;
}

.checkout-guarantee-block-title {
    color: #334155;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 4px;
}

.checkout-guarantee-block-text {
    color: #334155;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.checkout-guarantee-underline {
    background-color: #e2e8f0;
    height: 1px;
    width: 100%;
}

.checkout-plan-info {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    background: var(--white, #FFF);
}

.checkout-plan-info h4 {
    color: var(--black, #2E3443);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
}

.checkout-plan-price p {
    color: var(--black, #2E3443);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; 
}

.checkout-plan-total-price p {
    color: var(--black, #2E3443);
    text-align: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
}

.checkout-line {
    background: #F1F5F9;
    width: 100%;
    height: 1px;
}

.checkout-plan-text {
    color: var(--grey-2, #9399A5);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.checkout-satisfaction {
    display: flex;
    height: 60px;
    padding: 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background: var(--grey-4, #F2F2F2);
    color: var(--black, #2E3443);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
}

.checkout-satisfaction svg {
    color: #19F;
    font-size: 24px;
}

.checkout-button {
    display: flex;
    height: 60px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #6BD47B;
    width: 100%;
    border: none;
    color: #2e3443;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 24px;
}

.checkout-bottom-text {
    color: var(--black, #2E3443);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    width: 100%;
    margin-bottom: 0;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.checkout-bottom-text svg {
    font-size: 18px;
    color: #7D8898;
}

.checkout-bottom-label {
    color: var(--off-black, #7D8898);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.checkout-bottom-label-wrapper {
    padding: 24px;
}

.cards-block-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkout-plan-info-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #BFC2CA;
    border-radius: 16px;
    overflow: hidden;
}

.upgrade-success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    min-height: calc(100vh - 76px);
}

.upgrade-success-text {
    max-width: 360px;
    width: 100%;
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: -0.56px;
    margin-top: 24px;
    margin-bottom: 40px;
}

.upgrade-success-button {
    color: #2E3443;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    width: 320px;
    height: 60px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #6BD47B;
}

.upgrade-error-underline-text {
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    margin-top: 40px;
}

.upgrade-error-try-again {
    color: #419547;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    cursor: pointer;
}

.mobile-upgrade-text {
    display: none;
}

.desktop-upgrade-text {
    display: flex;
}

.new-flow-checkout-text {
    display: none;
}

.payment-paypal-block-text {
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
}

.payment-paypal-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px 0;
}

.paypal-button-wrapper {
    margin-top: 24px;
    width: 100%;
}

.loading-block-checkout {
    width: 100%;
    padding: 24px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-checkout {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    border-radius: 50%;
}

.loader-checkout {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
}

.loader-checkout {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    animation: spin89345 1s linear infinite;
}

.install-addon-block-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.install-addon-block-wrapper.vertical {
    flex-direction: column;
}
  
.addon-block-element {
    width: 338px;
    height: 280px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #D7D8DB;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
}

.addon-block-image {
    height: 60px;
}

.addon-block-title {
    font-family: "Inter";
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #2E3443;
}

.addon-block-description {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #2E3443;
}

.addon-block-button {
    width: 117px;
    height: 44px;
    padding: 10px 24px;
    border-radius: 8px;
    gap: 8px;
    background-color: #6BD47B;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #2E3443;
}

.page-content-wrapper {
    display: flex;
    gap: 24px;
    padding: 0 32px;
}

@keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
}

@media (max-width: 900px) {
    .pl-8 {
        padding-left: 0 !important;
    }
    .change-selected-plan-block {
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #e2e8f0;
        border-top: 1px solid #e2e8f0;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        left: 0;
        padding: 16px;
        position: fixed;
        top: 76px;
        width: 100%;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
    }

    .checkout-new-purchase-block {
        background: #fff;
        border-top: 1px solid #e2e8f0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        left: 0;
        padding: 16px;
        position: fixed;
        width: 100%;
    }

    .upgrade-success-text {
        max-width: 320px;
        width: 100%;
        color: #334155;
        text-align: center;
        font-family: "Inter";
        font-size: 23px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: -0.46px;
        margin-top: 24px;
        margin-bottom: 40px;
    }

    .upgrade-success-button {
        color: #2E3443;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        display: flex;
        width: 320px;
        height: 44px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #6BD47B;
    }

    .mobile-upgrade-text {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
        margin-top: 0px;
    }
    
    .desktop-upgrade-text {
        display: none;
    }

    .upgrade-text-title-mobile {
        margin-bottom: 8px !important;
    }

    .checkout-body {
        flex-direction: column;
    }

    .checkout-guarantee-block {
        padding: 0 16px;
    }

    .checkout-field-block {
        border: none;
        padding: 150px 16px 16px;
    }

    .checkout-field-block,
    .checkout-body-right,
    .checkout-header {
        width: 100%;
        border-radius: 0;
        border-top: 4px solid #e2e8f0;
    }

    .checkout-body-right {
        padding: 16px 0px 153px;
    }
    
    .checkout-guarantee-underline {
        height: 4px;
    }

    .checkout-body {
        gap: 0;
    }

    .checkout-page {
        gap: 0;
        padding: 0 !important;
    }

    .checkout-header span {
        font-size: 12px;
        line-height: 12px;
    }

    .checkout-header span img {
        height: 14px;
        width: auto;
        margin-right: 4px !important;
    }

    .checkout-header {
        padding: 16px !important;
        border-bottom: 1px solid #F2F2F2;
    }

    .checkout-body-header {
        font-size: 23px;
        font-weight: 700;
        line-height: 28px;
        text-align: start;
        padding: 16px !important;
        margin-top: 0px;
    }

    .checkout-header-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 100;
    }
    
    .checkout-card-field,
    .checkout-address-block {
        padding: 16px 0 !important;
    }

    .checkout-card-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 24px;
    }
    
    .select-payments-block-wrapper {
        width: 100%;
        align-items: center;
        background: #edf3f9;
        border-radius: 100px;
        display: flex;
        padding: 4px;
    }

    .header-none {
        display: none;
    }

    .checkout-address-block h2 {
        font-size: 19px;
        line-height: 23px;
    }

    .checkout-button {
        margin-top: 0px;
        height: 60px;
        font-size: 16px;
    }

    .payment-element-wrapper {
        border-bottom: none;
        padding: 0px;
    }

    .checkout-plan-info {
        border: none;
    }
    
    .checkout-satisfaction {
        display: flex;
        height: 60px;
        padding: 16px;
        border-radius: 0px;
        margin-top: 0px;
        border-top: 1px solid #F2F2F2;
        border-bottom: 1px solid #F2F2F2;
        background-color: transparent;
    }

    .checkout-plan-info-wrapper {
        display: flex;
        flex-direction: column;
        border: none;
        border-radius: 0px;
        overflow: hidden;
    }

    .checkout-button-wrapper {
        padding: 0px 16px;
    }

    .selecet-payment-method-element {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 8px;
        height: 36px;
        justify-content: center;
        padding: 8px 12px;
        width: 50%;
    }

    .under-text {
        display: none;
        color: var(--Grey-1, #7D8898);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding-top: 24px;
    }

    .new-flow-checkout-text {
        display: block;
        color: #6d6e78;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        border-bottom: 4px solid #e2e8f0;
        padding-bottom: 16px;
    }

    .paypal-button-wrapper {
        margin-top: 0px;
        width: 100%;
    }

    .install-addon-block-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .addon-block-element {
        width: 100%;
        height: fit-content;
        padding: 24px;
        border-radius: 8px;
        border: 1px solid #D7D8DB;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;
    }

    .page-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0;
    }

    .mobile-margin-addons-block {
        margin-bottom: 100px;
    }

    .mobile-padding-disable {
        padding-left: 0;
        padding-right: 0;
    }
}